import React from 'react';
import styled from 'styled-components';

import SEO from '../components/SEO';
import PageWrapper from '../containers/PageWrapper';

const Wrapper = styled.div`
  padding: 8rem 0;
  text-align: center;
`;

const NotFoundPage = () => (
  <PageWrapper>
    <SEO title="404 | Page Not Found | Penrose Grand Del Mar" />
    <Wrapper>
      <h1>Oops! Page not found.</h1>
    </Wrapper>
  </PageWrapper>
);

export default NotFoundPage;
